// fonts-section
body {
    font-feature-settings: "lnum" 1;
}

@font-face {
    font-family: "Ideal Sans SSm A";
    font-style: normal;
    font-weight: 200;
    src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-XLight_Web.woff2") format("woff2"),
        url("/fonts/IdealSansSSm/woff/IdealSansSSm-XLight_Web.woff") format("woff");
}

@font-face {
    font-family: "Ideal Sans SSm A";
    font-style: italic;
    font-weight: 200;
    src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-XLightItalic_Web.woff2") format("woff2"),
        url("/fonts/IdealSansSSm/woff/IdealSansSSm-XLightItalic_Web.woff") format("woff");
}

@font-face {
    font-family: "Ideal Sans SSm A";
    font-style: normal;
    font-weight: 300;
    src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-Light_Web.woff2") format("woff2"),
    url("/fonts/IdealSansSSm/woff/IdealSansSSm-Light_Web.woff") format("woff");
}

@font-face {
    font-family: "Ideal Sans SSm A";
    font-style: italic;
    font-weight: 300;
    src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-LightItalic_Web.woff2") format("woff2"),
    url("/fonts/IdealSansSSm/woff/IdealSansSSm-LightItalic_Web.woff") format("woff");
}

@font-face {
    font-family: "Ideal Sans SSm A";
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-Book_Web.woff2") format("woff2"),
    url("/fonts/IdealSansSSm/woff/IdealSansSSm-Book_Web.woff") format("woff");
}

@font-face {
    font-family: "Ideal Sans SSm A";
    font-style: italic;
    font-weight: 400;
    src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-BookItalic_Web.woff2") format("woff2"),
    url("/fonts/IdealSansSSm/woff/IdealSansSSm-BookItalic_Web.woff") format("woff");
}

@font-face {
    font-family: "Ideal Sans SSm A";
    font-style: normal;
    font-weight: 500;
    src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-Medium_Web.woff2") format("woff2"),
    url("/fonts/IdealSansSSm/woff/IdealSansSSm-Medium_Web.woff") format("woff");
}

@font-face {
    font-family: "Ideal Sans SSm A";
    font-style: italic;
    font-weight: 500;
    src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-MediumItalic_Web.woff2") format("woff2"),
    url("/fonts/IdealSansSSm/woff/IdealSansSSm-MediumItalic_Web.woff") format("woff");
}

@font-face {
    font-family: "Ideal Sans SSm A";
    font-style: normal;
    font-weight: 600;
    src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-Semibold_Web.woff2") format("woff2"),
    url("/fonts/IdealSansSSm/woff/IdealSansSSm-Semibold_Web.woff") format("woff");
}

@font-face {
    font-family: "Ideal Sans SSm A";
    font-style: italic;
    font-weight: 600;
    src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-SemiboldItalic_Web.woff2") format("woff2"),
    url("/fonts/IdealSansSSm/woff/IdealSansSSm-SemiboldItalic_Web.woff") format("woff");
}

@font-face {
    font-family: "Ideal Sans SSm A";
    font-style: normal;
    font-weight: 700;
    src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-Bold_Web.woff2") format("woff2"),
    url("/fonts/IdealSansSSm/woff/IdealSansSSm-Bold_Web.woff") format("woff");
}

@font-face {
    font-family: "Ideal Sans SSm A";
    font-style: italic;
    font-weight: 700;
    src: url("/fonts/IdealSansSSm/woff2/IdealSansSSm-BoldItalic_Web.woff2") format("woff2"),
    url("/fonts/IdealSansSSm/woff/IdealSansSSm-BoldItalic_Web.woff") format("woff");
}
