// footer


#footer-wrapper {
    position: relative;
}

// *******************************

// .footer margin-top should be 0, because of bottomizer

.footer {
    padding-bottom: 50px;
    background: $brand-footer;
}

.footer-background {
    position: absolute;
    width: 100%;
    opacity: 0.6;
    background: #000000;
}

.footer {

    .footer-column {
        padding-top: 50px;
        color: #ddd; // $text-color; // #fff;

        a,
        a:hover {
            text-decoration: none;
            color: #fff; // #fff;
        }

        a {
            opacity: 0.7;
            color: #ddd;

        }

        a:hover,
        a.active {
            opacity: 1;
        }

        ul {
            list-style: none;
            padding-left: 0;
            // padding-left: 20px;

            li {
                position: relative;
                padding-left: 13px;
            }

            .bullet {
                display: block;
                position: absolute;
                top: 11px;
                left: 0px;
                background: $text-color; // #fff;
                width: 6px;
                height: 6px;
                border-radius: 3px;
                opacity: 0.5;
            }

        }

    }

    // ***************

    .footer-legal {
        font-size: 70%;
        line-height: 1.3;
        opacity: 0.5;
        margin-bottom: 14px;

        .footer-legal-item {
            white-space: nowrap;
        }
    }


    .footer-small {
        font-size: $font-size-sm;
        line-height: $line-height-small;
        margin-bottom: 14px;
    }

    // ***************

    .footer-social {
        // height: 100%;
        display: table;
        float: right;
        margin-top: 50px;

        span {
            padding-left: 10px;
        }

        a:first-child span {
            padding-left: 0;
        }

    }

    .popover,
    .popover a,
    .popover a:hover {
        font-size: 80%;
        color: #000;
    }

    // ***************

    // .footer-link-contact,
    .footer-link-map {
        margin-top: 20px;
    }

    // ***************

    .row {
        position: relative;

        .footer-logo-icon {
            position: absolute;
            bottom: 0px;
            right: 15px;
        }
    }

    // ***************

    .footer-logo {
        @include clearfix();

        img {
            width: 100%;
            height: auto;
            float: right;
        }
    }
}

// show popovers (socials) if overlapping .container (desktop)

// *******************************

.hidden-container {
    display: block;
    position: absolute;
    visibility: hidden;
    z-index: -1;
    top: -500px;
}

// *******************************
// up and down arrows

#anchor-helper {
    position: fixed;
    bottom: 50%;
    margin-bottom: -86px;
    right: 20px;
    z-index: 900;

    a {
        font-size: 30px;
        line-height: 40px;
        color: #C0C0C0;
        opacity: 0.2;
        text-shadow: 0 0 1px #000;

    }

    a:hover {
        opacity: 0.5;
    }

}

@include media-breakpoint-down(md) {
    #anchor-helper {
        visibility: hidden;
        cursor: default;
    }
}
