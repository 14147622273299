// passage

// anchor helper
.passage-anchor {
  position: relative;
  top: -100px;
}

.passage {

  padding-top: 20px;
  padding-bottom: 0;
  margin: 0 auto;

   h2 {
     color: $brand-app;
   }

  p {
    font-weight: 300;
  }

  .bob-btn-transparent {
    color: #333;
    border: 1px solid $brand-app;
    font-size: 17px;
  }

}

@include media-breakpoint-up (sm) {
  .passage {
    padding-bottom: 30px;
    padding-top: 15px;
  }


  @include media-breakpoint-up (xl) {
    .passage {

      p {
        font-size: 19px;
      }
    }
  }

  .passage-first {
    padding-top: 70px;
  }
}

.passage-last {
  margin-bottom: 50px;

  a {
    color: $brand-app;
    text-decoration: none;
    border-bottom: 1px solid $brand-app;
  }
}