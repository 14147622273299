#scrolltop {
	position: fixed;
	z-index: 900;
	bottom: 20px;
	right: 20px;
	width: 34px;
	height: 34px;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.6);
	display: none;
	transition: all 0.3s ease;

	span {
		position: relative;
		transition: all 0.3s ease;
	}

	i,
	span.glyphicons {
		margin: 2px 3px 3px;
		fill: #fff;
	}

	&:focus,
	&:active {
		outline: 0;
		box-shadow: 0 0 0 0.2rem #d1d1d1;
	}
}

#scrolltop:hover {
	text-decoration: none;
}
