// glyphicons svg

.icons {
    display: none;
}

.glyphicons,
.glyphicons-not-copied {
	position: relative;
	top: 1px;
	display: inline-block;
	line-height: 1;
	vertical-align:top;

	svg {
		margin: 0 auto;
		width: 28px; 
		height: 28px; 
	}
}

span.glyphicons,
span.glyphicons-not-copied {
	top: 0;
}

.msg-success {
	.glyphicons svg path {
		fill: $success;
	}
}
.msg-warning {
	.glyphicons svg path {
		fill: $warning;
	}
}
.msg-error {
	.glyphicons svg path {
		fill: $danger;
	}
}