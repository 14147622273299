.btn-app {
	background-color: $brand-app;
	border-color: $brand-app;
	color: #fff;
}

.btn-app:hover,
.btn-app:focus,
.btn-app:active,
.btn-app.active,
.open>.dropdown-toggle.btn-app,
.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.open>.dropdown-toggle.btn-custom {
	color: #fff;
	background-color: lighten($brand-custom, 5%);
	border-color: $brand-app;
	/*set the color you want here*/
}

.btn-app:not(:disabled):not(.disabled).active,
.btn-app:not(:disabled):not(.disabled):active,
.show>.btn-app.dropdown-toggle {
	color: #fff;
	background-color: lighten($brand-custom, 5%);
	border-color: lighten($brand-custom, 5%);
}

.bob-btn-app {
	font-size: 15px;
	max-width: 200px;
}


@include media-breakpoint-up(sm) {
	.bob-btn-app {
		max-width: 300px;
	}
}
