// custom.less

html, body {
	height: 100%;
}
.body-content {
	position: relative;
	height: 100%;
}

// ********************
// button

.btn {
	font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
}

// Links

// .passage:not(.exemption-style-link) {
//   a {
//     color: $brand-app;
//     text-decoration: none;
//     border-bottom: 1px solid $brand-app;
//   }
// }



// ***************
// MODAL FIX
// ***************
// modal
@include media-breakpoint-down(sm) {
	.modal {
		.m-window {
			top: 0;
			width: 100%;
			height: 100%;
			margin-bottom: 0;
			border: 0 none;

			.m-content {
				background-color: #fff;
			}
		}
	}
	.modal-msg {
		border: 0 none;
	}
	body.modal-bob-open .body-content {
		display: none;
	}
}

// *****
// ios

.ios {
  .modal {
  	position: absolute;
  	.m-background {
  		display: none;
  		position: absolute;
  	}
    .m-window {
      top: 0;
      left: 0;
      width: 100%;
			height: 100%;
      margin-left: 0;
			margin-bottom: 0;
			border: 0 none;
      border-radius: 0;

      .m-content {
				background-color: #fff;
      	// width: 540px;
      	// margin: 0 auto;
      }
    }
  }
  .modal-msg {
    top: 0;
    left: 0;
    width: 100%;
    margin-left: 0;
    border-radius: 0;
		border: 0 none;
    .msg-content {
      border-radius: 0;
    }
  }
	body.modal-bob-open .body-content {
		display: none;
	}
}

@include media-breakpoint-up(sm) {
	.ios {
	  .modal {
	    .m-window {
	      .m-content {
	      	width: 600px;
	      	margin: 0 auto;
	      }
	    }
	  }
	}
}

// ***************
// nav button

.navbar-secondary .btn-nav,
.navbar-nav .btn-nav {
  background-color: $brand-app;
	color: #fff;
  margin-top: 3px;
  border: solid thin #fff;
	padding: 5px;
	margin-top: 0px;
  // .button-variant(#ffffff;
  // transparent;
  // #ffffff);

  // // line-height: ensure proper height of button next to small input
  // .button-size(@padding-small-vertical;
  // @padding-small-horizontal;
  // @font-size-small;
  // @line-height-small;
  // @btn-border-radius-small);
}

/* overide border-color */
.navbar-secondary .btn-nav:focus,
.navbar-nav .btn-nav:focus,
.navbar-secondary .btn-nav.focus,
.navbar-nav .btn-nav.focus {
  border-color: #ffffff;
}

.navbar-secondary .btn-nav:hover,
.navbar-nav .btn-nav:hover {
  border-color: #ffffff;
}

.navbar-secondary .btn-nav:active,
.navbar-nav .btn-nav:active,
.navbar-secondary .btn-nav.active,
.navbar-nav .btn-nav.active,
.open>.dropdown-toggle.navbar-secondary .btn-nav,
.open>.dropdown-toggle.navbar-nav .btn-nav {
  border-color: #ffffff;
}

.navbar-secondary .btn-nav:active:hover,
.navbar-nav .btn-nav:active:hover,
.navbar-secondary .btn-nav.active:hover,
.navbar-nav .btn-nav.active:hover,
.open>.dropdown-toggle.navbar-secondary .btn-nav:hover,
.open>.dropdown-toggle.navbar-nav .btn-nav:hover,
.navbar-secondary .btn-nav:active:focus,
.navbar-nav .btn-nav:active:focus,
.navbar-secondary .btn-nav.active:focus,
.navbar-nav .btn-nav.active:focus,
.open>.dropdown-toggle.navbar-secondary .btn-nav:focus,
.open>.dropdown-toggle.navbar-nav .btn-nav:focus,
.navbar-secondary .btn-nav:active.focus,
.navbar-nav .btn-nav:active.focus,
.navbar-secondary .btn-nav.active.focus,
.navbar-nav .btn-nav.active.focus,
.open>.dropdown-toggle.navbar-secondary .btn-nav.focus,
.open>.dropdown-toggle.navbar-nav .btn-nav.focus {
  border-color: #ffffff;
}

/* overide border-color - end */

.navbar-secondary {
  display: table;
  position: relative;
  float: right;
  padding-right: 15px;

  a {
    color: #ffffff;
  }

}

.navbar-right {
	a {
		margin-right: 15px;
	}
}

.nav-link {
  i {
    svg {
      width: 20px;
      height: 20px;
      margin: 1px 5px 0 0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .navbar-secondary {
    display: none;
	}
	
}

.navbar-btn-descr {
  display: none;
}

@include media-breakpoint-up(sm) {
  .navbar-btn-descr {
    display: inline-block;
    width: 150px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    padding-top: 7px;
    color: #fff;
  }
}