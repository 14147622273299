@mixin bob-btn-colors($color, $background, $border-color, $border-width, $color-effect){
	color: $color;
	background-color: $background;
	border-color: $border-color;
	cursor: pointer;
	display: -webkit-flex;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-align: center;
	position: relative;
	padding: 0;
	
	&:hover,
	&:active,
	&:focus {
		color: $color;
		background-image: none;
		text-decoration: none;
		box-shadow: none;
	}
	@include bob-btn-effect($color-effect);	

}

@mixin bob-btn-effect ($color) {
	overflow: hidden;

	-webkit-transition: background .3s ease-in-out;
	transition: background .3s ease-in-out;
	will-change: background;

	span {
		display: block;
		border-radius: 50%;
		position: absolute;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		z-index: 0;
	}

	&.feedback span {
		animation: feedback .6s ease-out;
		background: $color;
	}
}

@keyframes feedback {
	0% {
		opacity: 0.2;
		height: 0px;
		width: 0px;
	}
	100% {
		opacity: 0;
		height: 400px;
		width: 400px;
	}
}

@mixin bob-btn-size($radius, $max-width, $height, $font-size) {
	border-width: $border-width;
	border-radius: $radius;
	font-size: $font-size;
	height: $height;
	max-width: $max-width;
  }

  .bob-btn-lg {
	@include bob-btn-size(
		$radius:35px,
		$max-width:300px,
		$height:50px,
		$font-size: 19px
		);
  }

  .bob-btn-sm {
	@include bob-btn-size(
		$radius:25px,
		$max-width:158px,
		$height:45px,
		$font-size: 18px,
		);
  }

  .bob-btn-md {
	@include bob-btn-size(
		$radius:25px,
		$max-width:240px,
		$height:45px,
		$font-size: 18px,
		);
  }

  .bob-btn-flat-lg {
	@include bob-btn-size(
		$radius:35px,
		$max-width:265px,
		$height:44px,
		$font-size: 15px
		);
  }

  .bob-btn-100 {
	width: 100%;
	@include bob-btn-size(
		$radius:35px,
		$max-width:300px,
		$height:50px,
		$font-size: 19px
		);
  }

 

// smart4me button
.bob-btn-white {
	@include bob-btn-colors(
		$color:$brand-app,
		$background:#fff,
		$border-color:#fff,
		$border-width: 0px,
		$color-effect: rgb(161, 161, 161)
	);
}

.bob-btn-transparent {
	border: solid;
	@include bob-btn-colors(
		$color:$brand-app,
		$background: transparent,
		$border-color:$brand-app,
		$border-width: 3px,
		$color-effect: $brand-app
	);
}

.bob-btn-transparent-custom {
	border: solid;
	@include bob-btn-colors(
		$color:$brand-custom,
		$background: transparent,
		$border-color:$brand-custom,
		$border-width: 3px,
		$color-effect: $brand-custom
	);
}

.bob-btn-transparent-thin {
	border: thin solid;
	@include bob-btn-colors(
		$color: #333,
		$background: transparent,
		$border-color:$brand-app,
		$border-width: 3px,
		$color-effect: $brand-app
	);
}

// deprexis button
.bob-btn-red {
	@include bob-btn-colors(
		$color:#fff,
		$background:#eb7a7a,
		$border-color:#eb7a7a,
		$border-width: 0px,
		$color-effect: #fff
	);
}

.bob-btn-app {
	@include bob-btn-colors(
		$color:#fff,
		$background: $brand-app,
		$border-color:$brand-app,
		$border-width: 0px,
		$color-effect: #fff
	);
}

.bob-btn-custom {
	@include bob-btn-colors(
		$color:#fff,
		$background: $brand-custom,
		$border-color:$brand-custom,
		$border-width: 0px,
		$color-effect: #fff
	);
}

.bob-btn-custom-sec {
	@include bob-btn-colors(
		$color:#fff,
		$background: $brand-custom-sec,
		$border-color:$brand-custom-sec,
		$border-width: 0px,
		$color-effect: #fff
	);
}


.btn-center {
	left: 50%;
	top: 20px;
  	-webkit-transform: translate(-50%, -50%);
 	 transform: translate(-50%, -50%);
}