// NAV
// ***

.navbar-dark {
  background-color: $brand-app;
}

.icon-bar {
	width: 22px;
	height: 2px;
	background-color: #B6B6B6;
	display: block;
	transition: all 0.2s;
	margin-top: 4px
}

.navbar-toggler {
  border: none;
  // background: transparent;
}



.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}


@include media-breakpoint-up(md) {
    .page_1 .navbar li > a > .page_1,
    .page_2 .navbar li > a > .page_2,
    .page_3 .navbar li > a > .page_3,
    .page_4 .navbar li > a > .page_4 {

        border-bottom: 2px solid #ddd;
        color: #fff;
        opacity: 1;
    }
}