// SPINNER
// *******

@mixin animation-name($name){
  -webkit-animation-name: $name;
     -moz-animation-name: $name;
      -ms-animation-name: $name;
       -o-animation-name: $name;
          animation-name: $name;
}
@mixin animation-duration($seconds){
  -webkit-animation-duration: $seconds;
     -moz-animation-duration: $seconds;
      -ms-animation-duration: $seconds;
       -o-animation-duration: $seconds;
          animation-duration: $seconds;
}
@mixin animation-iteration-count($count){
  -webkit-animation-iteration-count: $count;
     -moz-animation-iteration-count: $count;
      -ms-animation-iteration-count: $count;
       -o-animation-iteration-count: $count;
          animation-iteration-count: $count;
}
@mixin animation-direction($direction){
  -webkit-animation-direction: $direction;
     -moz-animation-direction: $direction;
      -ms-animation-direction: $direction;
       -o-animation-direction: $direction;
          animation-direction: $direction;
}
@mixin animation-delay($seconds){
  -webkit-animation-delay: $seconds;
     -moz-animation-delay: $seconds;
      -ms-animation-delay: $seconds;
       -o-animation-delay: $seconds;
          animation-delay: $seconds;
}
@mixin rotate-translate($degrees, $x, $y){
  -webkit-transform: rotate($degrees) translate($x, $y);
     -moz-transform: rotate($degrees) translate($x, $y);
      -ms-transform: rotate($degrees) translate($x, $y);
       -o-transform: rotate($degrees) translate($x, $y);
          transform: rotate($degrees) translate($x, $y);
}


.spinner {
  display: none;
  float: left;
  position: relative;
  top: 24px;
  width: 16px;
  height: 1px;
  margin-left: 20px;

// **********
// rotate

  .blockG {
    position: absolute;
    width: 6px;
    height: 2px;
    border-radius: 1px;
    @include animation-name(fadeG);
    @include animation-duration(1.2s);
    @include animation-iteration-count(infinite);
    @include animation-direction(linear);
    background-color:#FFFFFF;
  }

  .rotateG_01 {
    @include animation-delay(-1s);
    @include rotate-translate(-90deg, 8px, 0px);
  }
  .rotateG_02 {
    @include animation-delay(-0.9s);
    @include rotate-translate(-60deg, 8px, 0px);
  }
  .rotateG_03 {
    @include animation-delay(-0.8s);
    @include rotate-translate(-30deg, 8px, 0px);
  }
  .rotateG_04 {
    @include animation-delay(-0.7s);
    @include rotate-translate(0deg, 8px, 0px);
  }
  .rotateG_05 {
    @include animation-delay(-0.6s);
    @include rotate-translate(30deg, 8px, 0px);
  }
  .rotateG_06 {
    @include animation-delay(-0.5s);
    @include rotate-translate(60deg, 8px, 0px);
  }
  .rotateG_07 {
    @include animation-delay(-0.4s);
    @include rotate-translate(90deg, 8px, 0px);
  }
  .rotateG_08 {
    @include animation-delay(-0.3s);
    @include rotate-translate(120deg, 8px, 0px);
  }
  .rotateG_09 {
    @include animation-delay(-0.2s);
    @include rotate-translate(150deg, 8px, 0px);
  }
  .rotateG_10 {
    @include animation-delay(-0.1s);
    @include rotate-translate(180deg, 8px, 0px);
  }
  .rotateG_11 {
    @include animation-delay(0s);
    @include rotate-translate(-150deg, 8px, 0px);
  }
  .rotateG_12 {
    @include animation-delay(0.1s);
    @include rotate-translate(-120deg, 8px, 0px);
  }
}

@-moz-keyframes fadeG {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@-webkit-keyframes fadeG {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@-ms-keyframes fadeG {
  0% {
    filter: alpha(opacity=100);
  }
  100% {
    filter: alpha(opacity=40);
  }
}
@-o-keyframes fadeG {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@keyframes fadeG {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}



// *************
// modal help

.spinner-modal-help {
  display: block;
  position: relative;
  .spinner {
    float: none;
    display: block;
    position: absolute;
    top: 40px;
    left: 50%;
    margin: 0;
    div {
      background-color: #f0f0f0; // $gray-light
    }
  }
}

// *************
// spinner fox

.total-cover,
.foggy-cover {
  display: none;

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.total-cover {
  z-index: 1101;
}
.foggy-cover {
  background: #fff;
  opacity: 0.6;
  z-index: 1100;
}

.total-cover .spinner {
  float: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  height: 160px;
  margin: -20px 0 0 -20px;
  z-index: 1102;


  div.blockG {
    width: 30px;
    height: 8px;
    border-radius: 4px;
    background-color: #8a8a8a; // before it waa $gray-light

    @include animation-name(fadeG);
  }

  .rotateG_01 {
    @include animation-delay(-1.0s);
    @include rotate-translate(-90deg, 40px, 0px);
  }
  .rotateG_02 {
    @include animation-delay(-0.9s);
    @include rotate-translate(-60deg, 40px, 0px);
  }
  .rotateG_03 {
    @include animation-delay(-0.8s);
    @include rotate-translate(-30deg, 40px, 0px);
  }
  .rotateG_04 {
    @include animation-delay(-0.7s);
    @include rotate-translate(0deg, 40px, 0px);
  }
  .rotateG_05 {
    @include animation-delay(-0.6s);
    @include rotate-translate(30deg, 40px, 0px);
  }
  .rotateG_06 {
    @include animation-delay(-0.5s);
    @include rotate-translate(60deg, 40px, 0px);
  }
  .rotateG_07 {
    @include animation-delay(-0.4s);
    @include rotate-translate(90deg, 40px, 0px);
  }
  .rotateG_08 {
    @include animation-delay(-0.3s);
    @include rotate-translate(120deg, 40px, 0px);
  }
  .rotateG_09 {
    @include animation-delay(-0.2s);
    @include rotate-translate(150deg, 40px, 0px);
  }
  .rotateG_10 {
    @include animation-delay(-0.1s);
    @include rotate-translate(180deg, 40px, 0px);
  }
  .rotateG_11 {
    @include animation-delay(0s);
    @include rotate-translate(-150deg, 40px, 0px);
  }
  .rotateG_12 {
    @include animation-delay(0.1s);
    @include rotate-translate(-120deg, 40px, 0px);
  }
}
