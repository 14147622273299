// ***************
// spots-usps global styles
// ***************

.usps {
	margin-top: 5px;
	margin-bottom: 0;
	padding-top: 10px;
	padding-bottom: 15px;
	color: #333;
	// background-color: #F8F8F8;

	.spot-usp {
		padding: 1px 0;
	}

	.usp-content {
		margin: 18px 0 0 0;
		position: relative;
		font-size: 14px;
	}

	h2 {
		// text-align: center;
		font-weight: 500;
		font-size: 20px;
		margin-left: 39px;
		margin-right: 40px;

		span {
			font-weight: 200;
		}

		&:before {
			content: "";
			margin-right: 20px;
			margin-left: -32px;
			display: inline-block;
			transform: rotate(45deg);
			height: 24px;
			width: 12px;
			border-bottom: 3px solid $brand-app;
			border-right: 3px solid $brand-app;
		}

	}

	.lg-only {
		display: none;
	}

	p {
		// font-family: $font-family-goth;
		font-weight: 300;
		font-size: 17px;
	}
}

@include media-breakpoint-up (sm) {

	.usps {
		display: block;
	}
}

@include media-breakpoint-up (md) {

	.usps {
		display: block;

		h2 {
			letter-spacing: 1px;
		}
	}
}

@include media-breakpoint-up (lg) {

	.usps {

		h2 {
			line-height: 28px;
		}

		.lg-only {
			display: block;
		}
	}
}

@include media-breakpoint-up (xl) {

	.usps {
		padding-left: 77px;

		h2 {
			letter-spacing: 1.2px;
			font-size: 23px;
		}
	}
}