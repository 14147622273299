$modal-content-fallback-border-color: #999;


body.bob-frame-open .body-content {
  display: none;
}
.body-content {
  position: relative;
}

.bob-frame {
  display: none;
  overflow: hidden;
  position: relative;
  padding-left: calc(100vw - 100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1040;
  background: #ffffff;

  &.bob-fading-in {
    position: fixed;
    top: -1;
    left: 0;
  }


  .bob-window {
    position: relative;
    width: 600px;
    margin: 0 auto 20px;

    .bob-header {

      img {
        display: block;
        width: auto;
        height: 44px;
        margin: 10px 0 0 27px;
      }
    }

    .bob-close-vis {
      position: fixed;
      top: 0px;
      right: 0px;
      padding: 5px 10px;
      cursor: pointer;
      font-size: 30px;
      line-height: 30px;
    }
  }
}

.bob-msg {
  display: none;
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 1080;
  // Remove focus outline from opened modal
  outline: none;
  border: 1px solid $modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
  border: 1px solid $modal-content-border-color;
  border-radius: $border-radius-lg;
  box-shadow: 0 3px 9px rgba(0,0,0,.5);
  background-color: #ffffff;
  cursor: pointer;

  .msg-content {
    display: none;
    border-radius: $border-radius-lg;
    padding: 30px 30px 30px 25px;
    @include clearfix();

    i,
    span.glyphicons {
      float: left;
      width: 40px;
      font-size: 24px;
    }
    span:not(.glyphicons) {
      display: block;
      margin-left: 40px;
    }
  }

  .msg-success {
    i,
    span.glyphicons {
      color: $success; // #3c763d; // #4cae4c; // #82D182;
    }
  }

  .msg-error {
    i,
    span.glyphicons {
      color: $danger; // #a94442; // #d43f3a; // #E8AEB7;
    }
  }

  .msg-warning {
    i,
    span.glyphicons {
      color: $warning; // #ffc107;
    }
  }

  .msg-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px;
    font-size: 24px;
    line-height: 24px;
  }

}

@include media-breakpoint-down(sm) { // < 768px
  .bob-frame {
    .bob-window {
      top: 0;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      border: 0 none;
    }
  }
  .bob-msg {
    top: 13px;

    .msg-close {
      padding: 5px 20px 5px 10px;
    }
  }
  body.bob-frame-open .body-content {  // this caused problems, i dont know why this was icludet in the first place
   display: none;
  }
}
