// jumbotron

.jumbotron {
  width: 100%;
  // background-image: url("/images/hero_image.jpg");
  height: 250px;
  margin-top: 5px;
  margin-bottom: 0;
  padding: 0;
}

.jumbotron .container {
  position: relative;
  height: 100%;
}

.jumbotron-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 57px;
  padding-bottom: 200px;
  z-index: 11;
  
  h2 {
    // font-size: ($font-size-base * 1.3);
    font-size: 18px;
    font-weight: bold;
    color: #333;
    // margin-bottom: 4px;
    // margin-bottom: 1px solid;
  }

  h1 {
    margin-top: 30px;
    font-size: 18px;
  }

  .bob-btn-transparent {
    color: #333;
    border: 1px solid $brand-app;
  }

}

.headings {
  border-bottom: 1px solid;
  height: 30px;
  // line-height: 1;

  .heading {
    display: none;
  }
  
  .txt-rotate {
  
    .wrap {
      border-right: 0.08em solid #666
    }
  }

}

@media only screen and (min-width: 310px) {
  .jumbotron-content {
    h2 {
      font-size: 20px;
    }

  }

  .headings {
    height: 34px;
  }
}


@media only screen and (min-width: 325px) {
  .jumbotron-content {
    h2 {
      font-size: 20px;
    }
  }
}

@media only screen and (min-width: 370px) {
  .jumbotron-content {
    h2 {
      font-size: 23px;
    }

  }
}


@include media-breakpoint-up(sm) {
  .jumbotron {
    height: 390px;
  }

  .jumbotron-content {
    padding-top: 130px;
    width: 556px;

    h2 {
      font-size: 36px;
      letter-spacing: 2px;
    }

    h1 {
      font-size: 28px;
    }
  }

  .headings {
    height: 52px;
  }

}